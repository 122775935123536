import React from 'react';
import Content from "../../../components/Content";

export default () => {
    return (
        <div>
            {/*<Content
                image="/img/projects/residential/smallbutclassyapartment2018/kypseli-1.jpg"
                imageTitle="'Small but Classy'"
                imageText="2018 | Apartment in Kypseli"
                alt="Small But Classy Apartment"
            />
            <Content
                image="/img/projects/residential/smallbutclassyapartment2018/kypseli-2.jpg"
                alt="Small But Classy Apartment 2"
            />
            <Content
                image="/img/projects/residential/smallbutclassyapartment2018/kypseli-3.jpg"
                alt="Small But Classy Apartment 3"
            />
            <Content
                image="/img/projects/residential/smallbutclassyapartment2018/kypseli-4.jpg"
                alt="Small But Classy Apartment 4"
            />
            */}
            <Content
                image="/img/projects/residential/smallbutclassyapartment2018/kypseli-plan.jpg"
                imageTitle="'Small but Classy'"
                imageText="2018 | Apartment in Kypseli"
                alt="Small But Classy Apartment"
            />
            <Content
                image="/img/projects/residential/smallbutclassyapartment2018/kypseli-5.jpg"
                alt="Small But Classy Apartment 5"
            />
            <Content
                image="/img/projects/residential/smallbutclassyapartment2018/kypseli-6.jpg"
                alt="Small But Classy Apartment 6"
            />
            <Content
                image="/img/projects/residential/smallbutclassyapartment2018/kypseli-7.jpg"
                alt="Small But Classy Apartment 7"
            />
            <Content
                image="/img/projects/residential/smallbutclassyapartment2018/room-yellow-1.jpg"
                alt="Room Yellow"
            />
            <Content
                image="/img/projects/residential/smallbutclassyapartment2018/room-yellow-2.jpg"
                alt="Room Yellow 2"
            />
        </div>
    );
};
