import React from 'react';
import Content from "../../../components/Content";

export default () => {
    return (
        <div>
            <Content
                image="/img/projects/residential/greyhouse2019/bathroom-wood-tile-1.jpg"
                imageTitle="The Grey Project"
                imageText="2019 | Palaio Faliro"
                alt="The Grey Project"
            />
            <Content
                image="/img/projects/residential/greyhouse2019/bathroom-wood-tile-2.jpg"
                alt="The Grey Project"
            />
        </div>
    );
};
