import React from 'react';
import Content from "../../../components/Content";

export default () => {
    return (
        <div>
            <Content
                image="/img/projects/residential/elianthos2007/01-general-dp.jpg"
                imageTitle="Elianthos Gi"
                imageText="2007 | Gythio"
                alt="general dp"
            />
            <Content
                image="/img/projects/residential/elianthos2007/02-stone-house-traditional.jpg"
                alt="stone house traditional"
            />
            <Content
                image="/img/projects/residential/elianthos2007/03-stone-house-sketch.jpg"
                alt="stone house sketch"
            />
        </div>
    );
};
