import React from 'react';
import Content from "../../components/Content";

export default () => {
    return (
        <div>
            <Content
                image="/img/projects/offices/blownbythewind2018/blown-by-the-wind-1.jpg"
                imageTitle="'Blown by the Wind'"
                imageText="2018 | Nea Smyrni"
                alt="Blown by the Wind"
            />
            <Content
                image="/img/projects/offices/blownbythewind2018/blown-by-the-wind-2.jpg"
                alt="Blown by the Wind 2"
            />
            <Content
                image="/img/projects/offices/blownbythewind2018/blown-by-the-wind-3.jpg"
                alt="Blown by the Wind 3"
            />
            <Content
                image="/img/projects/offices/blownbythewind2018/blown-by-the-wind-4.jpg"
                alt="Blown by the Wind 4"
            />
            <Content
                image="/img/projects/offices/blownbythewind2018/blown-by-the-wind-5.jpg"
                alt="Blown by the Wind 5"
            />
        </div>
    );
};
