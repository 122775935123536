import React from 'react';
import Content from "../../../components/Content";

export default () => {
    return (
        <div>
            <Content
                image="/img/projects/residential/prigkiponnison2016/01-floor-appartment.jpg"
                imageTitle="Home Sweet Home"
                imageText="2016 | Polygono"
                alt="floor appartment"
            />
            <Content
                image="/img/projects/residential/prigkiponnison2016/02-kitchen-interior.jpg"
                alt="kitchen interior"
            />
            <Content
                image="/img/projects/residential/prigkiponnison2016/03-italian-tile-bathroom.jpg"
                alt="italian tile bathroom"
            />
        </div>
    );
};
