import React, { Component } from 'react';
import Content from '../components/Content';

class Home extends Component {
    render() {
        return (
            <div>
                <Content
                    href="projects/commercial/soil"
                    image="/img/projects/commercial/soil2021/_AY51320.jpg"
                    alt="Soil Restaurant 1"
                    imageTitle="Soil Restaurant"
                />
                <Content
                    href="projects/commercial/soil"
                    image="/img/projects/commercial/soil2021/_AY51318.jpg"
                    alt="Soil Restaurant 2"
                    imageTitle="Soil Restaurant"
                />
                <Content
                    href="projects/commercial/soil"
                    image="/img/projects/commercial/soil2021/_AY51611.jpg"
                    alt="Soil Restaurant 3"
                    imageTitle="Soil Restaurant"
                />
                <Content
                    href="projects/commercial/soil"
                    image="/img/projects/commercial/soil2021/_AY51680.jpg"
                    alt="Soil Restaurant 4"
                    imageTitle="Soil Restaurant"
                />
                <Content
                    image="/img/home/1.jpg"
                    alt="Space is the breath of art. --Frank Lloyd Wright"
                />
                <Content
                    href="projects/residential/paros"
                    image="/img/projects/residential/paros2020/paros-a1.jpg"
                    imageTitle="Four Summer Houses"
                    alt="Paros"
                />
                <Content
                    image="/img/home/4.jpg"
                    alt=""
                />
                <Content
                    image="/img/home/5.jpg"
                    alt=""
                />
                <Content
                    image="/img/home/6.jpg"
                    alt=""
                />
                <Content
                    image="/img/home/8.jpg"
                    alt=""
                />
            </div>

        );
    }
}

export default Home;