import React, { Component } from 'react';
import Content from '../components/Content';

class About extends Component {
    render() {
        return (
            <div>
                <Content
                    title="Dimitra Gogi"
                    text={<span className="content__profile">
                        <img src="/img/profile.jpg" alt="Profile Pic" />
                        Dimension Studio was founded by Dimitra at 2008. She has studied Architecture in NTUA (National Technical University of Athens) and "Interior Design for Commercial Spaces" in IED (Barcelona). For the past years, she has been involved in various architectural projects (design concepts, site supervision, construction).
                        <br />Creativity and innovation are her main concerns. Her goal is to make the customers everyday life pleasant.
                    </span>}
                />
            </div>
        );
    }
}

export default About;
