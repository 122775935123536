import React from 'react';
import Project from './Project';
import { Route, useRouteMatch } from "react-router-dom";

export default () => {
    let { path } = useRouteMatch();

    return <Route path={[`${path}/:topicId/:projectId`, `${path}/:topicId`]}>
        <Project />
    </Route>;
};