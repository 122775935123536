import React from 'react';
import Content from "../../../components/Content";

export default () => {
    return (
        <div>
            <Content
                image="/img/projects/graphic/bcn.jpg"
                alt="bcn"
            />
            <Content
                image="/img/projects/graphic/commisaria-vilafranca-concept-design.jpg"
                alt="Commisaria vilafranca concept design"
            />
            <Content
                image="/img/projects/graphic/panel-tanatori.png"
                alt="Panel tanatori"
            />
        </div>
    );
};
