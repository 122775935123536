import React, { Component } from 'react';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import Page from './Layout/Page';
import Hamburger from './components/Hamburger';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

class Main extends Component {
  render() {
    return (
      <Router>
          <Hamburger />
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-3">
                <Header />
              </div>
              <div className="col-md-12 col-lg-9">
                <Switch>
                  <Route exact path="/" render={(props) => <Page {...props} name="home" />} />
                  <Route path="/projects" render={(props) => <Page {...props} name="projects" />} />
                  <Route exact path="/about" render={(props) => <Page {...props} name="about" />} />
                  <Route exact path="/contact" render={(props) => <Page {...props} name="contact" />} />
                  <Route render={(props) => <Page {...props} name="404" />} />
                </Switch>
              </div>
            </div>
            <div className="row d-block d-sm-block d-md-block d-lg-none">
              <Footer />
            </div>
          </div>
      </Router>
    );
  }
}

export default Main;