import React from 'react';
import Content from "../../../components/Content";

export default () => {
    return (
        <div>
            <Content
                image="/img/projects/residential/playfulapartment2018/02-herringbone-detail.jpg"
                imageTitle="Playful Apartment"
                imageText="2018 | Cholargos"
                alt="kitchen herringbone"
            />
            <Content
                image="/img/projects/residential/playfulapartment2018/01-kitchen-herringbone.jpg"
                alt="herringbone detail"
            />
                <Content
                    image="/img/projects/residential/playfulapartment2018/03-bathroom-bw-tiles.jpg"
                    alt="bathroom bw tiles"
                />
                <Content
                    image="/img/projects/residential/playfulapartment2018/04-bathroom-detail-grey.jpg"
                    alt="bathroom detail grey"
                />
                <Content
                    image="/img/projects/residential/playfulapartment2018/05-bathroom-detail-grey.jpg"
                    alt="bathroom detail grey 2"
                />
                <Content
                    image="/img/projects/residential/playfulapartment2018/06-bathroom-bw-general.jpg"
                    alt="bathroom bw general"
                />
                <Content
                    image="/img/projects/residential/playfulapartment2018/07-bathroom-blue.jpg"
                    alt="bathroom blue"
                />
                <Content
                    image="/img/projects/residential/playfulapartment2018/08-sofa-christmas.jpg"
                    alt="sofa christmas"
                />
        </div>
    );
};
