import React from 'react';
import Content from "../../../components/Content";

export default () => {
    return (
        <div>
            <Content
                href="projects/commercial/soil"
                image="/img/projects/commercial/soil2021/_AY51326.jpg"
                imageTitle="Soil Restaurant"
                imageText="2021 | Pangrati"
                alt="Soil Restaurant"
            />
            <Content
                href="projects/commercial/kampi"
                image="/img/projects/commercial/kampi2015/kampi-1.jpg"
                imageTitle="Beach Bar"
                imageText="2015 | Fournoi Ikarias"
                alt="Beach Bar"
            />
        </div>
    );
};
