import React from 'react';
import Content from "../../../components/Content";

export default () => {
    return (
        <div>     
            <Content
                title="Four Summer Houses"
                text='Πρόκειται για τέσσερεις ανεξάρτητες κατοικίες  οι οποίες λειτουργούν ως τουριστικά καταλύματα. Βασική πρόθεση υπήρξε η δημιουργία ενός κατακερματισμένου όγκου ο οποίος "αγκαλιάζει" το τοπίο και προσαρμόζεται στην φυσική μορφολογία του εδάφους. Το λευκό χρώμα της κυκλαδίτικης αρχιτεκτονικής συνεργάζεται αρμονικά με την πέτρα. Τα γήινα χρώματα εξυπηρετούν τον ίδιο σκοπό.'
            />
            <Content
                image="/img/projects/residential/paros2020/paros-1.jpg"
                imageTitle="Four Summer Houses"
                imageText={<span>2020 | Dryos, Paros<br /><span className="small-text">in collaboration with Marouso Marinopoulou, Architect NTUA</span></span>}
                alt="Paros Four summer houses"
            />
            <Content
                image="/img/projects/residential/paros2020/paros-a.jpg"
                alt="Paros A"
            />
            <Content
                image="/img/projects/residential/paros2020/paros-a1.jpg"
                alt="Paros A1"
            />
            <Content
                image="/img/projects/residential/paros2020/paros-b.jpg"
                alt="Paros B"
            />
            <Content
                image="/img/projects/residential/paros2020/paros-ccc.jpg"
                alt="Paros C"
            />
            <Content
                image="/img/projects/residential/paros2020/paros-d.jpg"
                alt="Paros D"
            />
            <Content
                image="/img/projects/residential/paros2020/paros-e1.jpg"
                alt="Paros E1"
            />
            <Content
                image="/img/projects/residential/paros2020/paros-e2.jpg"
                alt="Paros E2"
            />
            <Content
                image="/img/projects/residential/paros2020/paros-f.jpg"
                alt="Paros F"
            />
            {/*
            <Content
                image="/img/projects/residential/paros2020/paros-2.jpg"
                alt="Paros 2"
            />
            */}
            <Content
                image="/img/projects/residential/paros2020/paros-3.jpg"
                alt="Paros 3"
            />
            <Content
                image="/img/projects/residential/paros2020/paros-4.jpg"
                alt="Paros 4"
            />
            <Content
                image="/img/projects/residential/paros2020/paros-5.jpg"
                alt="Paros 5"
            />
            <Content
                image="/img/projects/residential/paros2020/paros-6.jpg"
                alt="Paros 6"
            />
            {/*<Content
                image="/img/projects/residential/paros2020/paros-7-under-construction.jpg"
                alt="Under Construction"
            />*/}
        </div>
    );
};
