import React from 'react';
import Content from "../../components/Content";

export default () => {
    return (
        <div>
            <Content
                image="/img/projects/offices/connecttotechnology2015/connect-to-technology-1.jpg"
                imageTitle="Connect to Technology"
                imageText="2015 | Nea Ionia"
                alt="Connect to Technology"
            />
            {/*
            <Content
                image="/img/projects/offices/connecttotechnology2015/connect-to-technology-2.jpg"
                alt="Dimension Studiol 2"
            />
            */}
            <Content
                image="/img/projects/offices/connecttotechnology2015/connect-to-technology-3.jpg"
                alt="Dimension Studio 3"
            />
            <Content
                image="/img/projects/offices/connecttotechnology2015/connect-to-technology-4.jpg"
                alt="Dimension Studio 4"
            />
            <Content
                image="/img/projects/offices/connecttotechnology2015/connect-to-technology-5.jpg"
                alt="Dimension Studio 5"
            />
        </div>
    );
};
