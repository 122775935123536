import React from 'react';
import Content from "../../../components/Content";

export default () => {
    return (
        <div>
            <Content
                image="/img/projects/residential/itsagirlsgame2017/01-kitchen.jpg"
                imageTitle="'It's a Girls Game'"
                imageText="2017 | Pangrati"
                alt="kitchen"
            />
            <Content
                image="/img/projects/residential/itsagirlsgame2017/02-entrance-tree.jpg"
                alt="entrance tree"
            />
            <Content
                image="/img/projects/residential/itsagirlsgame2017/03-bathroom-tree.jpg"
                alt="bathroom tree"
            />
            <Content
                image="/img/projects/residential/itsagirlsgame2017/04-detail-furniture.jpg"
                alt="detail furniture"
            />
            <Content
                image="/img/projects/residential/itsagirlsgame2017/05-detail-bathroom.jpg"
                alt="detail bathroom"
            />
            <Content
                image="/img/projects/residential/itsagirlsgame2017/06-detail-pink.jpg"
                alt="detail pink"
            />
        </div>
    );
};
