import React from 'react';
import Content from "../../components/Content";

export default () => {
    return (
        <div>
             <Content
                title="Soil Restaurant"
                text="Το διώροφο νεοκλασικό κτίσμα δημιουργήθηκε το 1925 ως κατοικία και σήμερα λειτουργεί ως fine dining restaurant. Στόχος της ανακαίνισης υπήρξε η διατήρηση της μνήμης, καθώς και ο απόλυτος σεβασμός στην ιστορία του κτιρίου. Έμπνευση για την επιλογή των υλικών και χρωμάτων αποτέλεσε η γη, η πέτρα, το χώμα. Αποφεύχθηκαν ριζικές παρεμβάσεις, καθώς ως βασική πρόθεση τέθηκε η απόλυτη ένταξη στο υπάρχον κέλυφος. Ο όμορφος κήπος στον ακάλυπτο συνεχίζει να διατηρεί τα χαρακτηριστικά ενός αστικού κήπου της τότε εποχής με τη φύτευση που υπήρχε (κυρίως εσπεριδοειδή), η οποία πλέον αναδεικνύεται με κατάλληλο φωτισμό."
            />
            <Content
                image="/img/projects/commercial/soil2021/_AY51326.jpg"
                imageTitle="Soil Restaurant"
                imageText="2021 | Pangrati"
                alt="Soil Restaurant"
            />
            <Content
                image="/img/projects/commercial/soil2021/_AY51336.jpg"
                alt="Soil Restaurant 2"
            />
            <Content
                image="/img/projects/commercial/soil2021/_AY51345.jpg"
                alt="Soil Restaurant 3"
            />
            <Content
                image="/img/projects/commercial/soil2021/_AY51463.jpg"
                alt="Soil Restaurant 5"
            />
            <Content
                image="/img/projects/commercial/soil2021/_AY51608.jpg"
                alt="Soil Restaurant 6"
            />
            <Content
                image="/img/projects/commercial/soil2021/_AY51383.jpg"
                alt="Soil Restaurant 4"
            />
        </div>
    );
};
